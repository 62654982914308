import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import useQuery from '../hooks/useQuery';

import { createBrowserHistory } from 'history';
import { LastLocationProvider } from 'react-router-last-location';

import AuthLayout from '../layouts/AuthLayout';
import BaseLayout from '../layouts/BaseLayout';

import { signinSuccess } from '../store/actions/auth';
import AuthTemplate from '../templates/AuthTemplate';
import ExternalLogin from '../views/ExternalLogin/ExternalLogin';
import FakeLogin from '../views/FakeLogin/FakeLogin';
import Home from '../views/Home';
import NotFound from '../views/NotFound';

import { appQuery } from '../store/actions/app';
import ExternalSigninUp from '../views/ExternalSigninUp/ExternalSigninUp';
import RedirectView from '../views/RedirectView/RedirectView';
import Voucher from '../views/Voucher';
import MovieVouchers from '../views/movieVouchers';

const Game = React.lazy(() => import('../views/Game'));
const ResetPassword = React.lazy(() => import('../views/ResetPassword'));
const Profile = React.lazy(() => import('../views/Profile'));
const UserActivated = React.lazy(() => import('../views/UserActivated'));
const Contact = React.lazy(() => import('../views/Contact/Contact'));

const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, layoutCmp: Layout, ...rest }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryApp = query.get('app');
  const queryToken = query.get('token');

  useEffect(() => {
    const localQueryApp = localStorage.getItem('queryApp');

    if (queryApp || localQueryApp) {
      queryToken && localStorage.setItem('token', queryToken);
      localStorage.setItem('queryApp', true);
      queryToken && dispatch(signinSuccess(queryToken));
      if (queryApp) {
        dispatch(appQuery(queryApp));
      } else {
        dispatch(appQuery(localQueryApp));
      }
    }
  }, [queryApp, queryToken]);

  return (
    <Route
      {...rest}
      render={props =>
        rest.isLogged !== null || queryToken ? ( // ||queryApp
          <Layout {...props} {...rest} cmp={Component} />
        ) : (
          <Redirect
            to={{
              pathname: !rest.ownLogin ? '/' : '/auth/login',
              state: {
                from: rest.location.pathname,
                isLogged: rest.isLogged,
              },
            }}
          />
        )
      }
    />
  );
};

const Routes = props => {
  const { ownLogin, multipromo } = props;
  const token = useSelector(state => state.auth.token);

  const appName = useSelector(state => state.app.config.code);

  let impersonate = null;
  let ownRoutes = null;

  if (!token) {
    if (ownLogin) {
      ownRoutes = (
        <React.Fragment>
          <Route path='/auth/externalsignin/:token' exact component={ExternalLogin} />
          <Route path='/auth/externalsigninup/:token' exact component={ExternalSigninUp} />

          <Route path='/auth/register/:token*' exact component={AuthTemplate} />
          <Route path='/auth/login' exact component={AuthTemplate} />
          <Route path='/auth/recovery-password' exact component={AuthTemplate} />

          <Route path='/activate/:id' exact component={UserActivated} />

          {appName && appName === 'WURTH' && (
            <Route path='/wuerthapp' exact component={RedirectView} />
          )}

          <Route
            path='/reset-password/:onetimetoken'
            exact
            render={route => <AuthLayout cmp={ResetPassword} {...route} />}
          />
        </React.Fragment>
      );
    }
  } else {
    ownRoutes = (
      <Route
        render={props => (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: props.location.pathname,
                isLogged: token,
              },
            }}
          />
        )}
      />
    );
  }
  if (ownLogin) {
    impersonate = <Route path='/impersonate/:token' exact component={FakeLogin} />;
  }

  return (
    <Router history={history}>
      <LastLocationProvider>
        <Switch>
          <PrivateRoute
            path='/'
            exact
            //component={Home}
            component={Home}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/i-miei-buoni'
            exact
            component={MovieVouchers}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/voucher/:id'
            exact
            component={Voucher}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />

          <PrivateRoute
            path='/promo/:initiative/game/:id'
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type='gamelayout'
          />

          <PrivateRoute
            path='/user/profile'
            exact
            component={Profile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />

          <PrivateRoute
            path='/contact'
            exact
            component={Contact}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <Route path='/impersonate/:token' exact component={FakeLogin} />
          {impersonate}

          {ownRoutes}

          <Route component={NotFound} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
  };
};

export default connect(mapStateToProps)(Routes);
