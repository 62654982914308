import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NavigationItem from './NavigationItem/NavigationItem';
import { Text } from '../../Ui';
import { respondTo } from '../../../theme/mixin';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../store/actions';

const NavUl = styled.ul`
  .item {
    cursor: pointer;
    ${respondTo.sm`
   display: flex;
   height: 100%;
    text-decoration: none;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    z-index:1; 
  `};

    span {
      display: block;
      transition: 0.4s;
      color: ${({ theme }) => theme.colore_testo_menu};
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;

      ${respondTo.sm`
       margin-top: 0;
       margin-bottom: 0;
  `};
    }
    &.active,
    &:hover {
      background-color: ${({ theme }) => theme.background_testo_menu_attivo};
      font-size: 18px;

      span {
        color: ${({ theme }) => theme.colore_testo_menu_attivo};
      }
    }
  }
  list-style: none;
  padding: 15px;
  ${respondTo.sm`
    display: flex;
    align-items: stretch;
    height: 100%;
    box-shadow: none;
    padding: 0;
	`}
  .only-mobile {
    display: block;
    //border-top: 1px solid #dce4e8;
    //padding: 15px 0;

    ${respondTo.sm`
      display: none;
    `}
  }
`;

const NavigationItems = props => {
  const { isLogged, multipromo, i18l, idGame, isOpen, setIsMenuOpen, setHideBurger } = props;
  const { wishlist, cart } = useSelector(state => state.user.user);
  /* const { catalogs } = useSelector(state => state.products.products); */
  const num = useSelector(
    state => state.news?.news?.filter(item => !item.read && item.visible_in_list && item)?.length,
  );
  const { accetta_regolamento_concorso } = useSelector(
    state => state?.app?.config?.ownlogin?.gdpr || {},
  );
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});
  const dispatch = useDispatch();
  const [t] = useTranslation();
  /*"wishlist":{"visibility":"show","label":"Preferiti"},"cart":{"visibility":"show","label":"Carrello"},"news":{"visibility":"show","label":"Notifiche"}}} */
  const slug = 'get';
  const games = useSelector(state => state.game.games);
  const ulRef = node => {
    if (node) {
      node.childNodes.length === 0 ? setHideBurger(true) : setHideBurger(false);
    }
  };
  return (
    <NavUl isOpen={isOpen} ref={ulRef}>
      {isLogged ? (
        <>
          <NavigationItem link={`/`} active='true' onClick={() => setIsMenuOpen(false)}>
            <Text size={12} upper>
              Home
            </Text>
          </NavigationItem>

          {games[0] && (
            <NavigationItem
              link={`/promo/${slug}/game/${games[0]?.id}`}
              active='true'
              onClick={() => setIsMenuOpen(false)}
            >
              <Text size={12} upper>
                PARTECIPA
              </Text>
            </NavigationItem>
          )}

          <NavigationItem
            link='/i-miei-buoni'
            active='true'
            onClick={() => setIsMenuOpen(false)}
          >
            <Text size={12} upper>
              I MIEI BUONI
            </Text>
          </NavigationItem>
          <NavigationItem link='/user/profile' active='true' onClick={() => setIsMenuOpen(false)}>
            <Text size={12} upper>
              PROFILO
            </Text>
          </NavigationItem>
          <a href={accetta_regolamento_concorso?.pdf} className='item' target='_blank' >
            <Text size={12} upper>
              REGOLAMENTO
            </Text>
          </a>
          <li
            className='item'
            onClick={() => {
              setIsMenuOpen(false);
              dispatch(logout());
            }}
          >
            <Text size={12} upper>
              LOGOUT
            </Text>
          </li>
        </>
      ) : (
        <>
          <NavigationItem
            link={`/auth/register`}
            active='true'
            onClick={() => setIsMenuOpen(false)}
          >
            <Text size={16} upper bold>
              REGISTRATI
            </Text>
          </NavigationItem>
          <a href={accetta_regolamento_concorso?.pdf} className='item' target='_blank'>
            <Text size={12} upper>
              REGOLAMENTO
            </Text>
          </a>
        </>
      )}
    </NavUl>
  );
};

NavigationItems.propTypes = {
  isLogged: PropTypes.string,
};
export default NavigationItems;
