import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { Button, Flex } from '../Ui';
import Text from '../Ui/Text';

const Container = styled.div`
  border: ${props => props.borderWidth || '1px'} solid ${props => props.border || 'transparent'};
  background-color: ${props => props.bgColor || ''};
  background-image: ${props => (props.bgImg ? `url(${props.bg})` : '')};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${props => props.radius || props.theme.border_radius_generale_grande};
  width: 100%;
  margin-bottom: ${props => props.marginBottom || '25px'};
  position: relative;
  top: ${props => props.top || '0'};
  overflow: hidden;
  height: ${({ moreCta }) => moreCta && 'fit-content'};
  ${respondTo.sm`
    width:${({ width }) => (+width === 100 ? '100%' : 'calc(' + width + '% - 1%)')};
    margin-left: ${({ width }) => (+width !== 100 ? 'auto' : '')};
    margin-right: ${({ width }) => (+width !== 100 ? 'auto' : '')};
  `}

  ul {
    list-style-type: disc;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: ${({ moreCta }) => (moreCta ? 'initial' : 'cover')};
  }
  .link {
    position: static;
    margin: 0 auto;
    margin-top: auto;

    button {
      padding: 0 70px;
    }
    ${respondTo.sm`
     
     ${({ position, moreCta }) =>
       position && !moreCta
         ? css`
             position: absolute;
             left: 50%;
             bottom: 30px;
             transform: translateX(-50%);
           `
         : css`
             position: static;
           `}
`}
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: ${props => props.padding || '40px'};
  padding-bottom: ${({ moreCta }) => moreCta && '30px'};
`;

function Notification({ bg, data, notif, promo, width }) {
  const style = notif && JSON.parse(notif?.style);
  const titlePromo = notif?.i18l.title;
  const contentPromo = notif?.i18l.content;
  const valueBtn = notif?.cta_label ? notif?.cta_label : 'PARTECIPA';
  const ctaUrl = notif?.cta_url?.split(';');
  const ctaLabel = notif?.cta_label?.split(';');

  return (
    <Container
      data={data}
      bg={bg}
      bgImg={notif?.bg_image}
      top={style?.top}
      bgColor={notif?.background_color}
      border={style?.border}
      radius={style?.radius}
      borderWidth={style?.borderWidth}
      marginBottom={style?.marginBottom}
      width={width}
      position={Boolean(notif?.img)}
      moreCta={ctaLabel?.length > 1}
    >
      {notif?.img && !notif?.bg_image && <img src={notif?.img} alt='' />}
      <Content
        bg={bg}
        padding={style?.padding}
        moreCta={ctaLabel?.length > 1}
        bgImg={notif?.bg_image}
      >
        {titlePromo && <Text
          size={30}
          bold
          style={{ color: notif?.text_color, margin: '30px 0' }}
          align={style?.align || 'center'}
          width={style?.width}
        >
          {titlePromo}
        </Text>}
        
        <Text as='p' width={style?.width} color={notif?.text_color} size={14} className='mb-30'>
          {promo ? contentPromo : notif?.i18l.content}
        </Text>
        {promo && (
          <Link to={`/promo/${notif.promotion.slug}`} className='link'>
            <Button active style={{ margin: '0 auto' }}>
              {valueBtn}
            </Button>
          </Link>
        )}
        {Boolean(notif?.show_cta) && (
          <Flex gap='30px' justify='center' className='link'>
            {ctaUrl.map((item, i) => (
              <Link to={item}>
                <Button active={i === 0} secondary={i !== 0}>
                  {ctaLabel[i]}
                </Button>
              </Link>
            ))}
          </Flex>
        )}
      </Content>
    </Container>
  );
}

export default Notification;
